import React, { useEffect, useRef } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import Image from '../../common/Image/Image'
import Button from '../../common/Button/Button'
import useFn from '../../../hooks/useFn'

import styles from './FreeGiftMobileHint.module.css'

const FreeGiftMobileHint = withPlugin(props => {
  const takenButtonRef = useRef()

  const trackView = useFn(() => {
    props.$track.event({ category: 'Wheel', action: `view_free_gift` })
  })


  useEffect(() => {
    trackView()
  }, [trackView])

  const handleClick = () => {
    if (props.link) {
      props.$router.navigate(props.link)
    }
  }

  const handleClickClaimIt = () => {
    return props.onTake({
      triggerRef: takenButtonRef,
      skuId: props.skuId
    })
  }

  return (
    <div className={styles.wrapper} style={props.style}>
      <div className={styles.card}>
        <div className={styles.close} onClick={props.onClose}>&#xe60e;</div>
        <div className={styles.freeGiftImage} onClick={handleClick}>
          <Image src={props.productImage} tiny />
        </div>
        <div className={styles.title}>{ props.$i18n.transl('core.promotion.freeGift.youHaveAFreeGift')}</div>
        <Button ref={takenButtonRef} className={styles.btn} onClick={handleClickClaimIt}>
          {props.$i18n.transl('core.promotion.freeGift.claimIt')}
        </Button>
      </div>
    </div>
  )
})

export default FreeGiftMobileHint
